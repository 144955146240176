import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from 'app/shared/util/react-util';
import {IWidgetUser} from 'app/shared/model/erp/widget-user.model';
import {IWidget} from 'app/shared/model/erp/widget.model';
import {WidgetUserService} from 'app/shared/services/erp/widget-user.service';
import {AccountService} from 'app/core/auth/account.service';
import {flatMap, map} from 'rxjs/operators';
import {IAccount} from 'app/core/user/account.model';

@Component({
  selector: 'app-widgets-preferences-menu',
  templateUrl: './widgets-preferences-menu.component.html',
  styleUrls: ['./widgets-preferences-menu.scss']
})
export class WidgetsPreferencesMenuComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  userWidgets: IWidgetUser[] = [];

  @Input()
  widgets: IWidget[] = [];

  widgetsMap = new Map<number, boolean>();

  @Output()
  saved = new EventEmitter<void>();

  @Input()
  showArrowIcon: boolean = false;

  isSaving: boolean = false;

  account: IAccount;

  private subscriptions: Subscription[] = [];

  constructor(private translateService: TranslateService,
              private widgetUserService: WidgetUserService,
              private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.setAccount();
    this.fillWidgetMap();
  }

  ngOnChanges() {
    this.fillWidgetMap();
  }

  setAccount() {
    this.subscriptions.push(
      this.accountService.identity().subscribe(account => {
        this.account = account;
      })
    );
  }

  fillWidgetMap() {
    this.widgetsMap = new Map<number, boolean>();
    const userWidgetsIds = this.userWidgets.map(userWidget => userWidget.widget?.id);
    this.widgets.forEach(widget => {
      const hasWidget = userWidgetsIds.includes(widget.id);
      this.widgetsMap.set(widget.id, hasWidget);
    });
  }

  save(widgetId): void {
    const widget = this.getWidgetById(widgetId);
    const isSelected = this.widgetsMap.get(widget.id);
    if (isSelected) {
      this.delete(widget);
    }
    if (!isSelected) {
      this.create(widget);
    }
  }

  create(widget: IWidget) {
    this.subscriptions.push(
      this.accountService.identity()
        .pipe( //
          map(account => {
            this.account = account;
            return {
              'widget': widget,
              'userEmail': account.email
            } as IWidgetUser;
          }), //
          flatMap(widgetUser => this.widgetUserService.create(widgetUser)))
        .subscribe(() => {
          this.saved.emit();
        }));
  }

  delete(widget: IWidget) {
    this.account?.email && this.subscriptions.push(
      this.widgetUserService.delete(widget.id, this.account.email)
        .subscribe(() => {
          this.saved.emit();
        }));
  }

  getWidgetById(id): IWidget {
    return this.widgets.find(widget => widget.id === id);
  }

  onCheckboxClick(event: MouseEvent) {
    // Previne a mudança de estado
    event.preventDefault();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
