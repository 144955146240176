import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/shared/util/request-util';
import {IUser, User} from './user.model';
import {ICompany} from 'app/shared/model/company/company.model';
import moment from 'moment';
import {map} from 'rxjs/operators';
import {FeatureAuthority} from 'app/shared/model/enumerations/feature-authority.enum';
import {Authority} from 'app/shared/model/enumerations/authority.enum';
import {IProject} from 'app/shared/model/scrum/project.model';

type EntityResponseType = HttpResponse<IUser>;
type EntityArrayResponseType = HttpResponse<IUser[]>;

@Injectable({providedIn: 'root'})
export class UserService {
  public resourceUrl = `${SERVER_API_URL}api/usr/users`;

  constructor(private http: HttpClient) {
  }

  create(user: IUser): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(user);
    return this.http
      .post<IUser>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(user: IUser): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(user);
    return this.http
      .put<IUser>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(login: string): Observable<EntityResponseType> {
    return this.http
      .get<IUser>(`${this.resourceUrl}/${login}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IUser[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getActiveByCompany(company: ICompany): Observable<EntityArrayResponseType> {
    const options = createRequestOption({
      companyId: company.id
    });
    return this.http
      .get<IUser[]>(`${this.resourceUrl}/active-by-company`, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getAllActiveWithAuthority(authority: FeatureAuthority | Authority): Observable<EntityArrayResponseType> {
    const options = createRequestOption({
      authority
    });
    return this.http
      .get<IUser[]>(`${this.resourceUrl}/all-active-with-authority`, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  getActiveByCompanyAndProject(company: ICompany, project: IProject): Observable<EntityArrayResponseType> {
    const options = createRequestOption({
      companyId: company.id,
      projectId: project.id
    });
    return this.http
      .get<IUser[]>(`${this.resourceUrl}/active-by-company-and-project`, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  activate(id: number): Observable<{}> {
    return this.http.post(`${this.resourceUrl}/${id}/activate`, null);
  }

  deactivate(id: number): Observable<{}> {
    return this.http.post(`${this.resourceUrl}/${id}/deactivate`, null);
  }

  requestPasswordReset(id: number): Observable<{}> {
    const options = createRequestOption({portal: true});
    return this.http.post(`${this.resourceUrl}/${id}/password/reset`, null, {params: options});
  }

  delete(login: string): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${login}`);
  }

  sync(user: User) {
    const copy = this.convertDateFromClient(user);
    return this.http
      .post<IUser>(`${this.resourceUrl}/sync`, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  public convertDateFromClient(user: IUser): IUser {
    const copy: IUser = Object.assign({}, user, {
      createdDate: user.createdDate && user.createdDate.isValid() ? user.createdDate.toJSON() : undefined,
      lastModifiedDate: user.lastModifiedDate && user.lastModifiedDate.isValid() ? user.lastModifiedDate.toJSON() : undefined,
      birthDate: user.birthDate && moment(user.birthDate).isValid() ? moment(user.birthDate).toJSON() : undefined
    });
    return copy;
  }

  public convertFromServer(user: IUser): IUser {
    return {
      ...user,
      createdDate: user.createdDate ? moment(user.createdDate) : undefined,
      lastModifiedDate: user.lastModifiedDate ? moment(user.lastModifiedDate) : undefined,
      birthDate: user.birthDate ? moment(user.birthDate) : undefined
    } as IUser;
  }

  public convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? moment(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? moment(res.body.lastModifiedDate) : undefined;
      res.body.birthDate = res.body.birthDate ? moment(res.body.birthDate) : undefined;
    }
    return res;
  }

  public convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((user: IUser) => {
        user.createdDate = user.createdDate ? moment(user.createdDate) : undefined;
        user.lastModifiedDate = user.lastModifiedDate ? moment(user.lastModifiedDate) : undefined;
        user.birthDate = user.birthDate ? moment(user.birthDate) : undefined;
      });
    }
    return res;
  }
}
