import moment, {Moment} from 'moment';
import {IUserAuthority} from 'app/core/user/user-authority.model';
import {BaseEntity, IBaseEntity} from 'app/shared/model/root/base-entity.model';
import {Criteria} from 'app/shared/filter/criteria.model';
import {ICompanyUser} from 'app/shared/model/company/company-user.model';
import {Company, ICompany} from 'app/shared/model/company/company.model';
import {File, IFile} from 'app/shared/model/admin/file.model';
import {APP_LANGUAGES} from 'app/shared/util/language-util';
import {DocumentType} from 'app/shared/model/enumerations/document-type.enum';

export interface IUser extends IBaseEntity {
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  cpf?: string;
  cnpj?: string;
  documentType?: DocumentType;
  birthDate?: Moment;
  mobilePhone?: string;
  companyName?: string;
  avatar?: IFile;
  activated?: boolean;
  activatedDate?: Moment;
  langKey?: string;
  emailActivated?: boolean;
  emailActivationCode?: string;
  authorities?: IUserAuthority[];
  password?: string;
  companies?: ICompanyUser[];
  _company?: ICompany;
  changeToEmail?: string;
  account?: string;
  phone?: string;
  main?: boolean;
  externalUserId?: number;
  accountUserId?: string;
}

export class User extends BaseEntity implements IUser {
  constructor(
    public id?: any,
    public uuid?: string,
    public createdBy?: string,
    public createdDate?: Moment,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Moment,
    public active?: boolean,
    public login?: string,
    public firstName?: string,
    public companyName?: string,
    public lastName?: string,
    public email?: string,
    public cpf?: string,
    public cnpj?: string,
    public documentType?: DocumentType,
    public birthDate?: Moment,
    public mobilePhone?: string,
    public avatar?: IFile,
    public activated?: boolean,
    public activatedDate?: Moment,
    public langKey?: string,
    public emailActivated?: boolean,
    public emailActivationCode?: string,
    public authorities?: IUserAuthority[],
    public password?: string,
    public emailToChange?: string,
    public companies?: ICompanyUser[],
    public account?: string,
    public phone?: string,
    public main?: boolean,
    public externalUserId?: number,
    public accountUserId?: string,
    public _company?: ICompany,
    public _label?: string
  ) {
    super(id, uuid, createdBy, createdDate, lastModifiedBy, lastModifiedDate, active, _label);
  }

  public static criterias(): Criteria<User>[] {
    return Criteria.buildFrom(this.example())
      .filter(criteria => !['avatar', 'password', 'emailActivationCode', 'companies', 'authorities', '_changeEmail', 'companyName', 'emailToChange'].includes(criteria.property.name))
      .flatMap(criteria => (criteria.property.name === 'langKey' ? criteria.asEnum(() => APP_LANGUAGES.map(l => l.code)) : criteria));
  }

  public static example(): User {
    return new User(
      0,
      '',
      '',
      moment(new Date()),
      '',
      moment(new Date()),
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      DocumentType.NOT_INFORMED,
      moment(new Date()),
      '',
      new File(),
      false,
      moment(new Date()),
      '',
      false,
      '',
      [],
      '',
      '',
      [],
      '',
      '',
      false,
      0,
      '',
      new Company(),
      ''
    );
  }
}
